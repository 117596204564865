
import { defineComponent } from "vue";

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "required-income-certificate",
  components: {
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      selected: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      batchs: [],
      subbatchs: [],
      empbatchs: [],
      selectedEntity: [],
      selectedTrance: [],
      selectedCourseType: [],
      selectedCourse: [],
      selectedBatch: [],
      tableData: [
        {
          id: 1,
          association: "AEOSIB",
          institute: "A.B.C Bangladesh-Turkish Technical School",
          course: "Welding & Fabrication",
          batch: "1",
          start_date: "2019-05-26",
          end_date: "2019-08-26",
          reg_no: "2600009302",
          ref_no: "SEIP-AEOSIB-ABC-WF-T2-N01-001",
          name: "Ponkos Debnath",
          income_certificate: "",
          home_district: "Bogra",
          income: "57.53",
        },
        {
          id: 2,
          association: "AEOSIB",
          institute: "A.B.C Bangladesh-Turkish Technical School",
          course: "Welding & Fabrication",
          batch: "1",
          start_date: "2019-05-26",
          end_date: "2019-08-26",
          reg_no: "2600009303",
          ref_no: "SEIP-AEOSIB-ABC-WF-T2-N01-002",
          name: "Md Kawsar",
          income_certificate: "",
          home_district: "Jessore",
          income: "27.56",
        },
        {
          id: 3,
          association: "AEOSIB",
          institute: "A.B.C Bangladesh-Turkish Technical School",
          course: "Welding & Fabrication",
          batch: "1",
          start_date: "2019-05-26",
          end_date: "2019-08-26",
          reg_no: "2600009304",
          ref_no: "SEIP-AEOSIB-ABC-WF-T2-N01-003",
          name: "Mohammed Riad",
          income_certificate: "",
          home_district: "Rangpur",
          income: "30.84",
        },
      ],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "2px",
          selected: true,
        },
        {
          name: "Association",
          key: "association",
          sortable: true,
          selected: true,
        },
        {
          name: "Institute",
          key: "institute",
          sortable: true,
          selected: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
          selected: true,
        },
        {
          name: "Batch",
          key: "batch",
          sortable: true,
          selected: false,
        },
        {
          name: "Start Date",
          key: "start_date",
          sortable: true,
          selected: false,
        },
        {
          name: "End Date",
          key: "end_date",
          sortable: true,
          selected: false,
        },
        {
          name: "Registration Number",
          key: "reg_no",
          sortable: true,
          selected: false,
        },
        {
          name: "Reference Number",
          key: "ref_no",
          sortable: true,
          selected: false,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
          selected: true,
        },
        {
          name: "Income Certificate",
          key: "income_certificate",
          sortable: true,
          selected: false,
        },
        {
          name: "Home District",
          key: "home_district",
          sortable: true,
          selected: false,
        },
        {
          name: "Income",
          key: "income",
          sortable: true,
          selected: true,
        },
      ],
      tranceInfos: [
        {
          id: "1",
          name: "Tranche-1",
        },
        {
          id: "2",
          name: "Tranche-2",
        },
        {
          id: "3",
          name: "Tranche-3",
        },
        {
          id: "4",
          name: "Tranche-4",
        },
      ],
      courseTypes: [
        {
          id: "1",
          name: "New Entrant",
        },
        {
          id: "2",
          name: "Up Skill",
        },
        {
          id: "3",
          name: "Up Skill - Special (70-30)",
        },
        {
          id: "4",
          name: "New Entrant - Special (70-30)",
        },
      ],
      courseInfos: [
        {
          id: 1,
          name: "Welding & Fabrication (NE) ",
          code: "W&F (NE)",
          type: "New Entrant",
          Mgt: "Yes ",
          month: "3",
          trance: "TR-1 ",
          unit_cost: "43413",
        },
        {
          id: 2,
          name: "Electrical & Navigation Equipment Installation (NE) ",
          code: "E&N (NE) ",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          trance: "TR-1 ",
          unit_cost: "31674",
        },
      ],
      batchInfos: [
        {
          id: 1,
          name: "BT-1",
        },
        {
          id: 2,
          name: "BT-2",
        },
        {
          id: 3,
          name: "BT-3",
        },
        {
          id: 4,
          name: "BT-4",
        },
        {
          id: 5,
          name: "BT-5",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    headerFilter() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    selectedTableHeader() {
      const st = Array();
      this.tableHeader.map((item) => {
        if (item.selected) {
          st.push({ name: item.name, key: item.key });
        } else {
        }
      });
      return st;
    },
  },

  beforeMount() {
    this.selectedTableHeader();
  },
  computed: {},
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      web_url: Yup.string().required().label("Web Url"),
      postcode: Yup.string().required().max(4).label("Postcode"),
      registration_number: Yup.string().required().label("Registration Number"),
      registration_authority: Yup.string()
        .required()
        .label("Registration Authority"),
      telephone: Yup.number().required().min(11).label("Telephone"),
      entity_name: Yup.string().required().label("Name"),
      entity_short_name: Yup.string().required().label("Short Name"),

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentbatch: Yup.string().required().label("Present batch"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
